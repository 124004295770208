<template>
  <div class="Home">

    <page-header/>

    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <div class="uk-text-center uk-margin">
          <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
            <li>
              <router-link :to="{name: 'OrganizationExamRegister', params: { examId: 0}}">
                <span >{{ $t("organization.organizExamCode.orga") }}</span>
              </router-link>
            </li>
            <li>
              <span>{{ $t("organization.organizExamCode.users") }}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="uk-section uk-padding-small">
      <div class="uk-container">
        <div class="uk-card uk-card-default uk-width-1-1 uk-margin-top-small">
          <table class="uk-table uk-table-responsive uk-table-small uk-remove-margin-bottom">
            <tbody>
            <tr>
              <td class="tit uk-text-bold">{{ $t("organization.organizExamCode.orga") }}</td>
              <td style="width: 650px">
                <select
                    placeholder="Select ..."
                    class="uk-select uk-form-width-large uk-form-small" style="width: 647px;">
                  <option value="">{{ $t("organization.organizExamCode.orga") }} ....</option>
                </select>
              </td>
            </tr>
            <tr >
              <td class="tit">{{ $t("organization.organizExamCode.sName") }}</td>
              <td>
                <input type="text" class="uk-input uk-form-small"/>
              </td>
            </tr>
            <tr>
              <td class="tit">{{ $t("organization.organizExamCode.sId") }}</td>
              <td style="border-bottom: 1px solid #dee2fe">
                <input type="text" class="uk-input uk-form-small"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
          <div class="uk-width-1-3@m"></div>
          <div class="uk-width-1-3@m"></div>
          <div class="uk-width-1-3@m uk-margin-remove-left" uk-grid>
            <button type="button" class="qbtn_d uk-button uk-button-small uk-width-1-3@m"
                    @click="searchFilter()">{{ $t("organization.organizExamCode.search") }}
            </button>
            <button type="button" class="uk-flex uk-flex-right uk-width-2-3@m qbtn_d uk-button uk-button-small"
                    @click="defaultSetting()">{{ $t("organization.organizExamCode.dSet") }}
            </button>
          </div>
        </div>

        <div
            class="uk-width-1-1 uk-margin-top uk-margin-remove-left"
            uk-grid style="border-top: 1px solid #dee2fe">
          <div class="uk-width-1-3@m uk-margin-remove-left uk-padding-remove" uk-grid>
            <div class="uk-width-2-3@m uk-padding-remove"><p class="uk-margin-top uk-h4">
              {{ $t("organization.organizExamCode.tStu") }} :
              <label class="uk-label uk-margin-remove-bottom uk-h4">1</label></p></div>
            <div class="uk-width-1-3@m"></div>
          </div>
          <div class="uk-width-1-3@m">
            <p class="uk-margin-top uk-h4">{{ $t("organization.organizExamCode.tECode") }} :
              <label class="uk-label uk-label-danger uk-margin-remove-bottom uk-h4">
                {{ selected.length }} </label></p>
          </div>
          <div class="uk-width-1-3@m uk-margin-remove-left" uk-grid>
            <div class="uk-width-2-3@m"><p class="uk-margin-top uk-h4">{{ $t("organization.organizExamCode.checked") }}
              :
              <label class="uk-label uk-margin-remove-bottom uk-h4">{{ selected.length }}</label></p></div>
            <button type="button" class="uk-margin-top uk-flex uk-flex-right
                uk-width-1-3@m qbtn_d uk-button uk-button-small">{{ $t("organization.organizExamCode.sendAll") }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="uk-section uk-padding-small">
      <div class="uk-container">
        <div class="uk-card uk-card-default uk-box-shadow-medium">
          <table class="uk-table uk-table-divider uk-table-responsive uk-table-small">
            <thead>
            <tr>
              <th class="uk-text-center">
                <input
                    type="checkbox"
                    class="uk-checkbox"
                    v-model="selectAll"
                    @click="select"
                />
              </th>
              <th class="uk-text-center">No</th>
              <th class="uk-text-center">{{ $t("organization.organizExamCode.lName") }}</th>
              <th class="uk-text-center">{{ $t("organization.organizExamCode.uId") }}</th>
              <th class="uk-text-center">{{ $t("organization.organizExamCode.vSelect") }}
                <button type="button" class="uk-margin-left uk-button uk-button-primary uk-button-small">auto</button>
              </th>
              <th class="uk-text-center">{{ $t("organization.organizExamCode.email") }}</th>
              <th class="uk-text-center">{{ $t("organization.organizExamCode.orga") }}</th>
              <th class="uk-text-center">{{ $t("organization.organizExamCode.send") }}</th>
            </tr>
            </thead>
            <!-- v-if="examDetail.length < 0" -->
            <tbody>
            <tr class="uk-text-center" v-for="(item, index) in users" :key="index">
              <td>
                <input
                    type="checkbox"
                    class="uk-checkbox"
                    v-model="selected"
                    :value="item.id"
                    style="margin-top:10px"
                />
              </td>
              <td class="uk-text-middle"><h4 >{{ index + 1 }}</h4></td>

              <td class="uk-text-middle">
                <h5 >{{ item.lastName }}</h5>
              </td>
              <td class="uk-text-middle"><h5 >{{ item.userId }}</h5></td>
              <td >
                <select class="uk-select uk-form-width-small uk-form-small">
                  <option value="A" >A-type</option>
                  <option value="B" >B-type</option>
                  <option value="C" >C-type</option>
                  <option value="D" >D-type</option>
                </select>
              </td>
              <td class="uk-text-middle"><h5 >asdsdf@gmail.com</h5></td>
              <td class="uk-text-middle"><h5 >{{ item.userOrganization }} </h5></td>
              <td >
                <button type="button" class="qbtn_d uk-button uk-button-danger"
                        style="width:80px"
                >{{ $t("organization.organizExamCode.send") }}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <page-footer/>

  </div>

</template>
<script>
import ExamService from "../../services/ExamService";
import {mapGetters} from "vuex";
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

export default {
  name: "app",
  components: {
    PageHeader,
    PageFooter
  },
  computed: {
    ...mapGetters(["isLoggedIn", "currentUser"])
  },
  data() {
    return {
      selectAll: false,
      selected: [],
      users: [{firstName: "tseekuu", lastName: "tseekuu", userId: "cs12d025", userOrganization: "MUIS"}]

    };
  },
  created() {
  },
  mounted() {
    // this.loadCates("main", 1, 0);
  },
  methods: {
    async viewExamCodes(item) {
      try {
        const response = await ExamService.viewExamCodes(
            {examId: item}
        );

        if (response.data.status == 200) {
          this.examCodes = response.data.result.examcodes
        }
      } catch (error) {
        console.log(error)
      }
    },
    searchFilter() {

    },
    defaultSetting() {
      window.location.reload(true);
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let item in this.users) {
          this.selected.push(this.users[item].id);
        }
      }
    }
  }
};
</script>
<style scoped>
/* button */
.qbtn_d {
  background-color: rebeccapurple;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
}

.uk-label {
  font-weight: 300;
  font-size: medium;
}

/* table */
.uk-table td.tit {
  border-right: 1px solid #dee2fe;
  border-top: 1px solid #dee2fe;
  border-bottom: 1px solid #dee2fe;
  padding-top: 14px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #404348;
  background-color: #eeeeef;
}

.uk-table td {
  border-top: 1px solid #dee2fe;
  font-size: 13px;
}

.uk-table td.subtit {
  border: 1px solid #dee2fe;
  border-top: 1px solid #dee2fe;
  border-bottom: 1px solid #dee2fe;
  font-size: 13px;
  color: #404348;
  background-color: #eeeeef;
}
</style>
